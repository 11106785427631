var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('h2', [_vm._v("404")]), _c('p', [_vm._v("SORRY!网页不见了...")])]);
}];
export { render, staticRenderFns };